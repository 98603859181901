import WasmController from "react-lib/frameworks/WasmController";

// APIs
// BIL
import ARTransactionList from "issara-sdk/apis/ARTransactionList_apps_INF";
import ARInvoiceItemByItemList from "issara-sdk/apis/ARInvoiceItemByItemList_apps_INF";
import ARInvoiceItemByModeList from "issara-sdk/apis/ARInvoiceItemByModeList_apps_INF";
import ARInvoiceItemSummaryView from "issara-sdk/apis/ARInvoiceItemSummaryView_apps_INF";
import ReportMedicalFeeView from "issara-sdk/apis/ReportMedicalFeeView_apps_INF";
import PayerList from "issara-sdk/apis/PayerList_core";
import MasterAccountList from "issara-sdk/apis/MasterAccountList_apps_BILM";
import ARInvoiceItemPaymentView from "issara-sdk/apis/ARInvoiceItemPaymentView_apps_INF";
import ARReceiptList from "issara-sdk/apis/ARReceiptList_apps_INF";
import ARReceiptShiftList from "issara-sdk/apis/ARReceiptShiftList_apps_INF";
import ARReceiptDetail from "issara-sdk/apis/ARReceiptDetail_apps_INFM";
import ARBillingCloseShiftView from "issara-sdk/apis/ARBillingCloseShiftView_apps_INF";
import ReportARReceiptShiftView from "issara-sdk/apis/ReportARReceiptShiftView_apps_INF";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import ARReceiptPrintView from "issara-sdk/apis/ARReceiptPrintView_apps_INF";
import ARTransactionBillingStatusView from "issara-sdk/apis/ARTransactionBillingStatusView_apps_INF";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";
import { HandleGetReceiptCode } from "../BILInterface";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormARremittanceReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormARremittanceReport";
import FormARremittancePayReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormARremittancePayReport";
import FormPaymentARReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormPaymentARReport";
import FormCostCenterMedFeeSlip from "react-lib/apps/HISV3/BIL/pdfFormBil/FormCostCenterMedFeeSlip";
import moment from "moment";
import { createPDFReceipt } from "./BillPayment";
import { combinePdfFiles } from "react-lib/apps/HISV3/common/CommonInterface";

let toDate = moment();
let year = parseInt(toDate.format("YYYY")) + 543;

export type State = {
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any; // {cardName: LOADING || SUCCESS || ERROR}
  BillPaymentArSequence?: {
    sequenceIndex?: string | null;
    search?: {
      checkedReceiptDate?: boolean;
      checkedReceiptNumber?: boolean;
      checkedReceiptYear?: boolean;
      created_date?: any;
      lot_no?: string;
      fiscal_year?: string;
    };
    selectPaymentAr?: any;
    paymentARItem?: any;
    masterAccountList?: any;
    searchHistory?: any;
    receiptShiftArList?: any;
  };
};

export const StateInitial: State = {
  BillPaymentArSequence: {
    sequenceIndex: null,
    search: {
      checkedReceiptDate: true,
      checkedReceiptNumber: false,
      checkedReceiptYear: false,
      created_date: "",
      lot_no: "",
      fiscal_year: "",
    },
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentArSequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["cancel_receipt", {}]],
    },
  });

  const [accountRes, accountErr, accountNet] = await MasterAccountList.list({
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (accountRes) {
    controller.setState(
      {
        BillPaymentArSequence: {
          ...state.BillPaymentArSequence,
          sequenceIndex: "Action",
          masterAccountList: accountRes?.items || [],
          search: {
            checkedReceiptDate: true,
            created_date: `${toDate.format("DD/MM")}/${year}`,
          },
          searchHistory: {
            checkedPayer: false,
            checkedStatus: false,
            checkedDate: true,
            startDate: `${toDate.format("DD/MM")}/${year}`,
            endDate: `${toDate.format("DD/MM")}/${year}`,
          },
        },
      },
      () => Action(controller, { ...params })
    );
  }
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillPaymentArSequence) return;

  if (params.action === "getArTransaction") {
    HandleGetARTransaction(controller, params);
  } else if (params.action === "getARItem") {
    HandleGetARItem(controller, params);
  } else if (params.action === "printARReport") {
    HandlePrintARReport(controller, params);
  } else if (params.action === "printARReceipt") {
    HandlePrintARReceipt(controller, params);
  } else if (params.action === "printARReceiptCopy") {
    HandlePrintARReceiptCopy(controller, params);
  } else if (params.action === "paymentAR") {
    HandlePaymentAR(controller, params);
  } else if (params.action === "checkPaymentAR") {
    HandleCheckPaymentAR(controller, params);
  } else if (params.action === "updatePaymentAR") {
    HandleUpdatePaymentAR(controller, params);
  } else if (params.action === "getARHistory") {
    HandleGetARHistory(controller, params);
  } else if (params.action === "getARReceiptData") {
    HandleGetARReceiptData(controller, params);
  } else if (params.action === "deleteARReceiptData") {
    HandleDeleteARReceiptData(controller, params);
  } else if (params.action === "getARReceiptShift") {
    HandleGetARReceiptShift(controller, params);
  } else if (params.action === "closeARReceiptShift") {
    HandleCloseARReceiptShift(controller, params);
  } else if (params.action === "printARReceiptShift") {
    HandlePrintARReceiptShift(controller, params);
  } else if (params.action === "getInvoiceAr") {
    HandleGetInvoiceAr(controller, params);
  }
};

const HandleGetARTransaction = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  let search = state.BillPaymentArSequence?.search;

  const [arListRes, arListErr, arListNet] = await ARTransactionList.list({
    params: {
      created_date: search?.checkedReceiptDate ? search?.created_date : "",
      lot_no: search?.checkedReceiptNumber ? search?.lot_no : "",
      fiscal_year: search?.checkedReceiptYear ? search?.fiscal_year : "",
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arListRes) {
    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        arTransactionList: (arListRes?.items || [])?.filter(
          (item: any) => item.is_e_claim === false
        ),
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleGetARItem = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const selectPaymentAr = state.BillPaymentArSequence?.selectPaymentAr;

  const [[arItemRes], [arModeRes], [arSummaryRes], [arStatueRes]] = await Promise.all([
    ARInvoiceItemByItemList.list({
      params: {
        ar_transaction: selectPaymentAr?.id,
        pending: true,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    ARInvoiceItemByModeList.get({
      params: {
        ar_transaction: selectPaymentAr?.id,
        pending: true,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    ARInvoiceItemSummaryView.get({
      params: {
        ar_transaction: selectPaymentAr?.id,
        pending: selectPaymentAr?.pending,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    ARTransactionBillingStatusView.get({
      pk: selectPaymentAr?.id,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
  ]);

  if (arItemRes && arModeRes && arSummaryRes) {
    const [userRes] = await UserDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: arItemRes?.items?.[0]?.created_by,
    });

    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        paymentARItem: arItemRes?.items,
        paymentARMode: arModeRes?.items,
        invoiceArItem: arSummaryRes,
        paymentStatue: arStatueRes,
        paymentArId: selectPaymentAr?.id,
        payment: {
          invoiceSummary: arSummaryRes,
        },
        selectPaymentAr: {
          ...selectPaymentAr,
          created_by: `${userRes?.first_name} ${userRes?.last_name}`,
        },
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    params.callback?.(false);
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandlePrintARReport = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [arReportRes, arReportErr, arReportNet] = await ReportMedicalFeeView.get({
    params: {
      ar_transaction: state.BillPaymentArSequence?.paymentARItem?.[0]?.ar_transaction,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arReportRes) {
    // Create PDF
    let paymentARReportData = {
      ...arReportRes,
      user_name: state.django?.user?.full_name,
      code_formatted: state.BillPaymentArSequence?.selectPaymentAr?.code_formatted,
    };
    const docARReportDef: any = await FormPaymentARReport(paymentARReportData);
    (await getPdfMake(true)).createPdf(docARReportDef).open();

    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandlePrintARReceipt = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [arReportRes, arReportErr, arReportNet] = await ARReceiptPrintView.get({
    pk: params.id,
    params: {
      ignore_print_copy_flag: params.config_BIL_CAN_PRINT_RECEIPT_COPY,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arReportRes) {
    // Create PDF
    const receipt = await createPDFReceipt(controller, arReportRes, {
      ...params,
      printType: "cudent",
      isWatermark: params.isWatermark,
    });

    receipt?.open();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandlePrintARReceiptCopy = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [arReportRes, arReportErr, arReportNet] = await UserPermissionView.post({
    data: {
      config_BIL_CAN_PRINT_RECEIPT_COPY: null,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arReportRes) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    HandlePrintARReceipt(controller, {
      ...params,
      id: state.BillPaymentArSequence?.selectPaymentAr?.id,
      config_BIL_CAN_PRINT_RECEIPT_COPY: arReportRes?.config_BIL_CAN_PRINT_RECEIPT_COPY,
      isWatermark: true,
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleCheckPaymentAR = async (controller: any, params: any) => {
  const state = controller.getState();
  let payerID = state.BillPaymentArSequence?.selectPaymentAr?.payer_code_name
    ?.split("[")?.[1]
    ?.split("]")?.[0];

  let sumSendClaim = 0;
  let sumPayable = 0;

  let filterItems = (state.BillPaymentArSequence?.paymentARItem || [])
    .filter((item: any) => params.checkedIds.includes(item?.id))
    ?.map((item: any) => {
      if (params.orderBy === "Item") {
        sumSendClaim = sumSendClaim + Number(item?.total_send_claim_price);
        sumPayable = sumPayable + Number(item?.total_pay_price);
        return {
          ...item,
          pay: item?.total_send_claim_price,
        };
      } else if (params.orderBy === "Mode") {
        sumSendClaim = sumSendClaim + Number(item?.total_send_claim_price);
        sumPayable = sumPayable + Number(item?.total_pay_price);
        return {
          ...item,
          pay: item?.total_send_claim_price,
        };
      }
    });

  const [payerListRes, payerListErr, payerListNet] = await PayerList.list({
    params: { search: payerID },
    apiToken: controller.apiToken,
  });

  if (payerListRes?.items) {
    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        payment: {
          ...state.BillPaymentArSequence?.payment,
          payments: [{ type: "1", payment_no: "", payment_target: 0, value: "0.00" }],
          items: [...filterItems],
          paid_date: "",
          payer: payerListRes?.items?.[0],
          payer_target: payerListRes?.items?.[0],
          totalPrice: {
            sendClaim: sumSendClaim,
            payable: sumPayable,
          },
          priceText: {
            cashAmountText: 0,
            transferText: 0,
            chequeText: 0,
            installmentText: 0,
            totalText: 0,
            oweText: sumPayable,
          },
        },
      },
    });
  }
};

const HandleUpdatePaymentAR = async (controller: any, params: any) => {
  const state = controller.getState();
  const payment = state.BillPaymentArSequence?.payment;

  let priceText = {
    transferText: 0,
    chequeText: 0,
    installmentText: 0,
  };

  const updatePriceText = (payment?.payments || []).map((item: any) => {
    if (item?.type === 2) {
      priceText.transferText = priceText.transferText + Number(item.value);
    } else if (item?.type === 4) {
      priceText.chequeText = priceText.chequeText + Number(item.value);
    } else if (item?.type === 7) {
      priceText.installmentText = priceText.installmentText + Number(item.value);
    }
  });

  let totalText =
    Number(payment?.priceText?.cashAmountText) +
    Number(priceText?.transferText) +
    Number(priceText?.chequeText) +
    Number(priceText?.installmentText);

  let oweText = Number(payment?.totalPrice?.payable) - Number(totalText);

  controller.setState({
    BillPaymentArSequence: {
      ...state.BillPaymentArSequence,
      payment: {
        ...state.BillPaymentArSequence?.payment,
        priceText: {
          ...state.BillPaymentArSequence?.payment?.priceText,
          ...priceText,
          totalText: totalText,
          oweText: oweText < 0 ? 0 : oweText,
        },
      },
    },
  });
};

const HandlePaymentAR = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const payment = state.BillPaymentArSequence?.payment;

  let newPayments = (payment?.payments || [])?.map((item: any, index: number) => {
    if (index === 0 && Number(payment?.priceText?.cashAmountText) !== 0) {
      return {
        ...item,
        payment_no: `${(payment?.totalPrice?.payable || 0).toFixed(2)} / ${(
          Number(payment.priceText?.totalText) - Number(payment?.totalPrice?.payable)
        ).toFixed(2)}`,
        value: payment?.priceText?.cashAmountText,
      };
    } else {
      return item;
    }
  });

  let newItems = (payment?.items || [])
    .filter((item: any) => item !== undefined)
    ?.map((item: any) => {
      return {
        id: item?.id,
        pay: item?.total_pay_price,
      };
    });

  let dataPayment = {
    payments: newPayments?.filter((item: any) => Number(item?.value) !== 0),
    items: newItems,
    paid_date: payment?.paid_date,
    payer: payment?.payer?.id,
    payer_target: payment?.payer_target?.id,
    receipt_code: params.receipt_code,
  };

  const [arPaymentRes, arPaymentErr, arPaymentNet] = await ARInvoiceItemPaymentView.post({
    data: {
      ...dataPayment,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arPaymentRes) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    params.callback?.(false, true);

    HandlePrintARReceipt(controller, { id: arPaymentRes?.id });
    HandleGetReceiptCode(controller, { isAr: true });

    const [arListRes, arListErr, arListNet] = await ARTransactionList.list({
      params: {
        lot_no: state.BillPaymentArSequence?.selectPaymentAr?.lot_no,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    });

    const [
      [arItemRes, arItemErr, arItemNet],
      [arModeRes, arModeErr, arModeNet],
      [arSummaryRes, arSummaryErr, arSummaryNet],
      [arStatueRes, arStatueErr, arStatueNet],
    ] = await Promise.all([
      ARInvoiceItemByItemList.list({
        params: {
          ar_transaction: state.BillPaymentArSequence?.selectPaymentAr?.id,
          pending: true,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      }),
      ARInvoiceItemByModeList.get({
        params: {
          ar_transaction: state.BillPaymentArSequence?.selectPaymentAr?.id,
          pending: true,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      }),
      ARInvoiceItemSummaryView.get({
        params: {
          ar_transaction: state.BillPaymentArSequence?.selectPaymentAr?.id,
          pending: true,
        },
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      }),
      ARTransactionBillingStatusView.get({
        pk: state.BillPaymentArSequence?.selectPaymentAr?.id,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      }),
    ]);

    if (arItemRes && arModeRes && arSummaryRes) {
      const [userRes, userErr, userNet] = await UserDetailAPIView.retrieve({
        apiToken: controller.apiToken,
        pk: arItemRes?.items?.[0]?.created_by,
      });

      controller.setState({
        BillPaymentArSequence: {
          ...state.BillPaymentArSequence,
          paymentARItem: arItemRes?.items,
          paymentARMode: arModeRes?.items,
          invoiceArItem: arSummaryRes,
          paymentStatue: arStatueRes,
          paymentArId: state.BillPaymentArSequence?.selectPaymentAr?.id,
          payment: {
            invoiceSummary: arSummaryRes,
          },
          selectPaymentAr: {
            ...arListRes?.items?.[0],
            created_by: `${userRes?.first_name} ${userRes?.last_name}`,
          },
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: arPaymentErr,
      },
    });
  }
};

const HandleGetARHistory = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  let search = state.BillPaymentArSequence?.searchHistory;

  const [arReceiptListRes, arReceiptListErr, arReceiptListNet] = await ARReceiptList.list({
    params: {
      payer: search?.checkedPayer ? search?.payer?.id : "",
      status: search?.checkedStatus ? search?.status : "",
      start_date: search?.checkedDate ? search?.startDate : "",
      end_date: search?.checkedDate ? search?.endDate : "",
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  if (arReceiptListRes) {
    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        arReceiptList: arReceiptListRes?.items,
        paymentARItem: [],
        paymentARMode: [],
        invoiceArItem: {
          total_paid_price: "0.00",
          total_remain_price: "0.00",
          total_send_claim_price: "0.00",
        },
        selectPaymentAr: {
          payer: null,
        },
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleGetARReceiptData = async (controller: any, params: any) => {
  const state = controller.getState();

  const [
    [arItemRes, arItemErr, arItemNet],
    [arModeRes, arModeErr, arModeNet],
    [arSummaryRes, arSummaryErr, arSummaryNet],
    [payerListRes, payerListErr, payerListNet],
  ] = await Promise.all([
    ARInvoiceItemByItemList.list({
      params: {
        receipt: params?.data?.id,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    ARInvoiceItemByModeList.get({
      params: {
        receipt: params?.data?.id,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    ARInvoiceItemSummaryView.get({
      params: {
        receipt: params?.data?.id,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    PayerList.list({
      params: { search: params?.data?.payer_name },
      apiToken: controller.apiToken,
    }),
  ]);

  if (arItemRes && arModeRes && arSummaryRes) {
    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        paymentARItem: arItemRes?.items,
        paymentARMode: arModeRes?.items,
        invoiceArItem: arSummaryRes,
        selectPaymentAr: {
          ...params?.data,
          payer: payerListRes?.items?.[0],
        },
      },
    });
  }
};

const HandleDeleteARReceiptData = async (controller: any, params: any) => {
  const state = controller.getState();

  const [arReceiptRes, arReceiptErr, arReceiptNet] = await ARReceiptDetail.delete({
    pk: params.receipt,
    data: {
      username: params.username,
      password: params.password,
      note: params.note,
    },
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (arReceiptErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: arReceiptErr,
      },
    });
  } else {
    params.closedCancelReceipt?.(false);
    params.clearReceiptData?.([]);
    Action(controller, { ...params, action: "getARHistory" });
  }
};

const HandleGetARReceiptShift = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [arReceiptShiftRes, arReceiptShiftErr, arReceiptShiftNet] = await ARReceiptShiftList.list({
    params: {
      station_log: params?.station_log,
    },
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (arReceiptShiftRes) {
    controller.setState({
      BillPaymentArSequence: {
        ...state.BillPaymentArSequence,
        receiptShiftArList: arReceiptShiftRes?.items,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleCloseARReceiptShift = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
    errorMessage: {
      ...state.errorMessage,
      [params?.card]: null,
    },
  });

  const [arReceiptCloseRes, arReceiptCloseErr, arReceiptCloseNet] =
    await ARBillingCloseShiftView.put({
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

  if (arReceiptCloseRes) {
    // controller.setState({
    //   buttonLoadCheck: {
    //     ...state.buttonLoadCheck,
    //     [`${params.card}_${params.action}`]: "SUCCESS",
    //   },
    // });

    HandlePrintARReceiptShift(controller, { ...params, station_log: arReceiptCloseRes?.id });
    // HandleGetReceiptCode(controller, { isAr: true });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: arReceiptCloseErr,
      },
    });
  }
};

const HandlePrintARReceiptShift = async (controller: any, params: any) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [arReceiptReportRes, arReceiptReportErr, arReceiptReportNet] =
    await ReportARReceiptShiftView.get({
      params: {
        station_log: params?.station_log,
      },
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

  if (arReceiptReportRes) {
    let printDate = `${toDate.format("D")} ${toDate.locale("th").format("MMMM")} พ.ศ. ${year}`;

    const forms: any[] = [];

    arReceiptReportRes?.items?.map((item: any, index: number) => {
      if (item.report_name === "ใบนำส่งเงิน") {
        const remittanceData = {
          ...arReceiptReportRes?.items?.[0],
          params: {
            ...arReceiptReportRes?.items?.[0]?.params,
            closed_user_name:
              arReceiptReportRes?.items?.[0]?.params?.closed_user_name ||
              state.django?.user?.full_name,
          },
          date: printDate,
          user: state.django?.user?.full_name,
        };
        forms.push(FormARremittanceReport(remittanceData));
      } else {
        let fieldsKey: any[] = [];
        let fieldsItem;
        if (arReceiptReportRes?.items?.[index]?.report_type === "item") {
          fieldsItem = arReceiptReportRes?.items?.[index]?.fields.map((fields: any) => {
            if (fieldsKey.includes(fields.code)) {
              return null;
            } else {
              let price = 0;
              let newFieldsItem = arReceiptReportRes?.items?.[index]?.fields
                .filter((newFields: any) => newFields.code === fields.code)
                .map((newFields: any) => {
                  return (price =
                    Number(newFields.price_round_down.replaceAll(",", "")) + Number(price));
                });

              fieldsKey.push(fields?.code);

              return {
                code: fields.code,
                name: fields.name,
                no: fields.no,
                price: price.toFixed(2),
                price_round_down: price,
                price_satang: fields.price_satang,
              };
            }
          });
        }

        const remittancePayData = {
          ...arReceiptReportRes?.items?.[index],
          ...(arReceiptReportRes?.items?.[index]?.report_type === "item" && {
            fields: fieldsItem.filter((item: any) => item !== null),
          }),
          params: {
            ...arReceiptReportRes?.items?.[index]?.params,
            closed_user_name:
              arReceiptReportRes?.items?.[0]?.params?.closed_user_name ||
              state.django?.user?.full_name,
            start_date: arReceiptReportRes?.items?.[index]?.params?.start_date || printDate,
            end_date: arReceiptReportRes?.items?.[index]?.params?.end_date || printDate,
          },
          date: printDate,
          division: state.selectedDivision?.name,
        };
        forms.push(FormCostCenterMedFeeSlip(remittancePayData));
      }
    });

    const blobURL = await combinePdfFiles(forms);

    globalThis.open(blobURL);
    // const docDF: any = await FormARremittanceReport(remittanceData);
    // (await getPdfMake(true)).createPdf(docDF).open();

    // const docPayDF: any = await FormARremittancePayReport(remittancePayData);
    // (await getPdfMake(true)).createPdf(docPayDF).open();

    const [arReceiptShiftRes, arReceiptShiftErr, arReceiptShiftNet] = await ARReceiptShiftList.list(
      {
        params: {},
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      }
    );

    if (arReceiptShiftRes) {
      controller.setState({
        BillPaymentArSequence: {
          ...state.BillPaymentArSequence,
          receiptShiftArList: arReceiptShiftRes?.items,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });

      HandleGetReceiptCode(controller, { isAr: true, isShift: true });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
    });
  }
};

const HandleGetInvoiceAr = async (controller: any, params: any) => {
  const state = controller.getState();

  let paidPrice = 0;
  let remainPrice = 0;
  let sendClaimPrice = 0;

  let paymentARItem = params.paymentARItem
    ? params.paymentARItem
    : state.BillPaymentArSequence?.paymentARItem;

  const invoice = (params.checkedIds || [])?.map((item: any, index: number) => {
    let paymentAR = (paymentARItem || [])?.find((data: any) => data.id === item);
    paidPrice = paidPrice + Number(paymentAR?.total_paid_price || 0);
    remainPrice = remainPrice + Number(paymentAR?.total_pay_price || 0);
    sendClaimPrice = sendClaimPrice + Number(paymentAR?.total_send_claim_price || 0);
  });

  controller.setState({
    BillPaymentArSequence: {
      ...state.BillPaymentArSequence,
      payment: {
        ...state.BillPaymentArSequence?.payment,
        invoiceSummary: {
          total_paid_price: (sendClaimPrice - remainPrice).toFixed(2),
          total_remain_price: remainPrice.toFixed(2),
          total_send_claim_price: sendClaimPrice.toFixed(2),
        },
      },
      ...(params.paymentARItem && { paymentARItem: params.paymentARItem }),
    },
  });
};
