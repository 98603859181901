import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";
import { isNumber } from "react-simple-timefield";

const FONT_SIZE = 14;

const BLANK_NODE_MARGIN = -FONT_SIZE - 4;

/**
 * * ใช้ dontBreakRows: true ถ้าไม่ติดปัญหาแสดง hLineWidth แถวสุดท้ายของ table = 1 และระหว่างแถว = 0
 * id: column_{unique}-{number}
 * data - { prefix: "column"; end: "table-end" }
 * end - ต้องเพิ่ม node { id: "table-end", text: " "} เพื่อทราบ node สิ้นสุดของ table
 * @param node - ข้อมูล nodes จาก page break before
 * @param data - prefix node id
 * @returns boolean
 */
const decidePageBreakInsideColumn = (
  nodes: {
    currentNode: any;
    followingNodesOnPage: any;
    previousNodesOnPage: any;
  },
  data: { prefix: string; end: string } | { prefix: string; end: string }[]
) => {
  const { currentNode } = nodes;
  const items = Array.isArray(data) ? data : [data];

  const isEndNodeMatched = items.find((item) =>
    nodes.followingNodesOnPage.find((n: any) => n?.id === item.end)
  );

  // หากหน้าถัดไปมี End node แล้ว ไม่ต้อง Page break
  if (isEndNodeMatched) {
    return false;
  }

  // ตรวจสอบว่า Current node ตรงกับ prefix id หรือไม่
  const isCurrentNodeMatched = !!items.find((item) => currentNode.id?.startsWith(item.prefix));

  if (isCurrentNodeMatched) {
    const columnPrefix = currentNode.id.split("-")[0];

    // ฟังก์ชันเพื่อตรวจสอบว่าโหนดเป็นคอลัมน์บนหน้าเดียวกันหรือไม่
    // ** หมายเหตุ ระวัง prefix ซ้ำกัน เช่น
    // column_10 กับ column_1 อาจเช็คแล้วเข้าใจว่าเป็น node เดี่ยวกันได้
    const isColumnOnPage = (node: any) =>
      node.id?.startsWith(columnPrefix) && node?.id !== currentNode?.id;

    const hasFollowingColumn = nodes.followingNodesOnPage.some(isColumnOnPage);
    const hasPreviousColumn = nodes.previousNodesOnPage.some(isColumnOnPage);

    // คืนค่าเป็น true หากมีคอลัมน์ต่อไปแต่ไม่มีคอลัมน์ก่อนหน้า
    // ซึ่งแสดงว่าต้องตัดหน้า
    return !(hasFollowingColumn || !hasPreviousColumn);
  }

  return false;
};

/**
 * start: signature - Node - end: signature_last_content หรือ signature เดี่ยว ๆ
 * prefix - "signature"
 * @param node - ข้อมูล nodes จาก page break before
 * @param data - prefix node id
 * @returns boolean
 */
const decidePageBreakInsideGroup = (
  nodes: {
    currentNode: any;
    followingNodesOnPage: any;
    nodesOnNextPage: any;
  },
  prefix: string | string[]
) => {
  const { currentNode } = nodes;
  const prefixes = Array.isArray(prefix) ? prefix : [prefix];

  const isCurrentNodeMatched = !!prefixes.find((prefix) => currentNode.id?.startsWith(prefix));

  if (isCurrentNodeMatched && !currentNode.id?.includes("last_content")) {
    const pageNumbers = currentNode.pageNumbers;
    // ค้นหาโหนด "last_content" ที่เกี่ยวข้องกับ Current node
    const lastContent = [...nodes.followingNodesOnPage, ...nodes.nodesOnNextPage].find(
      (node: any) => node.id?.includes("last_content") && node.id?.includes(currentNode.id)
    );

    if (lastContent) {
      // คืนค่าเป็น true หากหมายเลขหน้าของ "last_content" แตกต่างจากหมายเลขหน้าของ Current node
      return lastContent?.pageNumbers?.toString() !== pageNumbers.toString();
    } else {
      // คืนค่าเป็น true หากหมายเลขหน้าของ Current node ไม่เท่ากับ 1 หรือมีหลายหมายเลขหน้า
      return pageNumbers.length !== 1 || pageNumbers[0] !== currentNode.pages;
    }
  }

  return false;
};

const FormSeparateMedicalExpensesReport = (props: any): Promise<TDocumentDefinitions> => {
  console.log("FormSeparateMedicalExpensesReport props:", props);
  const defaultLine = 30;

  const generateTableBody = (data: any, columns: any) => {
    console.log("generateTableBodydata :", data);
    let body: any = [];

    // Header of the Table
    body.push(
      [
        { text: "ลำดับ", bold: true, rowSpan: 2, alignment: "center", margin: [0, 15] },
        {
          text: "ชื่อ - นามสกุลผู้ป่วย",
          bold: true,
          rowSpan: 2,
          alignment: "center",
          margin: [0, 15],
        },
        { text: "เลขที่บัตร", bold: true, rowSpan: 2, alignment: "center", margin: [0, 15] },
        { text: "สิทธิการรักษา", bold: true, rowSpan: 2, alignment: "center", margin: [0, 15] },
        { text: "จำนวนเงินค่ารักษาพยาบาล", bold: true, colSpan: 3, alignment: "center" },
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "",
        { text: "เบิกได้", bold: true, alignment: "center", margin: [0, 5, 0, 0] },
        { text: "เบิกไม่ได้", bold: true, alignment: "center", margin: [0, 5, 0, 0] },
        { text: "จำนวนเงินสุทธิ", bold: true, alignment: "center", margin: [0, 5, 0, 0] },
      ]
    );

    if (data.fields?.length > 0) {
      (data.fields || []).forEach((item: any, index: any) => {
        let rowData = [
          { text: item.seq, id: `column_1-${index}` },
          {
            text: item.patient_name,
            id: `column_2-${index}`,
          },
          { text: item.employee_card || "\u00A0", id: `column_3-${index}` },
          { text: item.coverage_name, id: `column_4-${index}` },
          {
            text: handleToString(item.total_claimable),
            alignment: "right",
            id: `column_5-${index}`,
          },
          {
            text: handleToString(item.total_non_claimable),
            alignment: "right",
            id: `column_6-${index}`,
          },
          { text: handleToString(item.total), alignment: "right", id: `column_7-${index}` },
        ];
        body.push(rowData);
      });
    }

    body.push([
      { text: "รวมเป็นเงินทั้งสิ้น", colSpan: 4, alignment: "right" },
      "",
      "",
      "",
      { text: handleToString(data.params?.total_claimable), alignment: "right" },
      { text: handleToString(data.params?.total_non_claimable), alignment: "right" },
      { text: handleToString(data.params?.total_price), alignment: "right" },
    ]);

    return body;
  };

  const handleToString = (num: any) => {
    if (typeof(num) === "string" &&  (isNaN(parseFloat(num)) || parseFloat(num) === 0)) {
      return parseFloat(num).toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    } else if (typeof(num) === "number") {
      return num.toLocaleString("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    } else {
      return num
    }
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["5%", "25%", "12%", "22%", "12%", "12%", "12%"],
        headerRows: 2,
        body: generateTableBody(data, columns),
        margin: [15, 15, 15, 0],
      },
      layout: {
        hLineWidth: (i: any, node: any) => {
          const first = i < 3;
          const last = i >= (data.fields?.length || 0) + 2;

          const lastPageNumber = node.positions.slice(-1)[0]?.pageNumber?.toString();
          const currentPageNumber = node.table.body[i]?.[0]?.nodeInfo?.pageNumbers?.toString();

          const isDifferentPage = lastPageNumber !== currentPageNumber;

          return first || last || isDifferentPage ? 1 : 0;
        },
      },
    };
  };

  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    pageOrientation: "portrait",
    pageMargins: [15, 100, 15, 25 + BLANK_NODE_MARGIN],
    pageSize: "A4",
    styles: {
      fieldHeader: {
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      fieldKeyBold: {
        bold: true,
        color: "black",
      },
      tableData: {},
      miniFieldKey: {
        fontSize: 12.5,
        bold: true,
      },
      miniFieldValue: {
        fontSize: 12.5,
      },
      tablex: { margin: [0, 5, 0, 15], border: [0, 0, 0, 0] },
    },
    header: (currentPage: any, pageCount: any) => {
      return {
        margin: [15, 15, 15, 0],
        stack: [
          {
            columns: [
              {
                width: "*",
                marginTop: 0,
                stack: [
                  {
                    // text: `ค่ารักษาพยาบาลผู้ป่วย${
                    //   props.reportCovData?.params.coverage_name || "ทุกสิทธิ" 
                    // }`,
                    text: `ค่ารักษาพยาบาลผู้ป่วย${props.reportCovData?.fields?.[0]?.coverage_name || "ทุกสิทธิ"}`, // issue 67967
                    alignment: "center",
                    style: "fieldHeader",
                    lineHeight: 0.95,
                  },
                ],
              },
            ],
          },
          {
            margin: [10, 7.5, 10, 0],
            columns: [
              {
                width: "auto",
                text: "พิมพ์วันที่: ",
                style: "fieldKey",
              },
              {
                width: 120,
                text: props.reportCovData?.params.print_date_time
                  ? moment(props.reportCovData?.params.print_date_time || "", "DD/MM/YYYY HH:mm")
                      .locale("th")
                      .format("DD MMMM YYYY HH:mm")
                  : "",
                style: "fieldKey",
                margin: [5, 0, 5, 0],
              },
              {
                width: "auto",
                text: "วันที่: ",
                style: "fieldKey",
              },
              {
                width: 110,
                text: props.reportCovData?.params.start_date
                  ? moment(props.reportCovData?.params.start_date || "", "DD/MM/YYYY")
                      .locale("th")
                      .format("DD MMMM พ.ศ. YYYY")
                  : "",
                style: "fieldKey",
                margin: [5, 0, 5, 0],
              },
              {
                width: "auto",
                text: "ถึงวันที่: ",
                style: "fieldKey",
              },
              {
                width: 110,
                text: props.reportCovData?.params.end_date
                  ? moment(props.reportCovData?.params.end_date, "DD/MM/YYYY")
                      .locale("th")
                      .format("DD MMMM พ.ศ. YYYY")
                  : "",
                style: "fieldKey",
                margin: [5, 0, 5, 0],
              },
              [
                {
                  width: "*",
                  text: `หน้าที่: ${currentPage}/${pageCount}`,
                  alignment: "right",
                  margin: [5, 0, 5, 0],
                },
              ],
            ],
          },
          {
            margin: [0, -10, 0, 0],
            columns: [
              {
                text: `จุดรับเงิน : ${props.reportCovData?.station || props.reportCovData?.params.station || ""}`,
                alignment: "center",
              },
              {
                text: `ใบนำส่งเงิน : ${props.reportCovData?.document_no || props.reportCovData?.params.document_no || ""}`,
                alignment: "center",
              },
            ],
          },
        ],
      };
    },
    content: [
      tableReport(props.reportCovData || [], [
        "seq",
        "patient_name",
        "performed_date",
        "employee_card",
        "total_claimable",
        "total_non_claimable",
        "total",
      ]),
      { text: " ", id: "table-end", marginTop: BLANK_NODE_MARGIN },
      {
        margin: [35, 20, 5, 20],
        text: [
          {
            text: `ยอดเงินเป็นตัวอักษร (ยอดที่เบิกได้) `,
            margin: [0, 0, 10, 0],
          },
          {
            text: ` (${props.reportCovData?.params?.claimable_text || ""})`,
            style: "fieldKeyBold",
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        id: "signature",
        columns: [
          {
            stack: [
              {
                columns: [
                  {
                    width: 100,
                    text: `ผู้ส่งเอกสาร`,
                    alignment: "right",
                    margin: [0, 0, 10, 0],
                  },
                  {
                    stack: [
                      {
                        text: `${props.reportCovData?.params?.closed_user_name || ""}`,
                        relativePosition: { x: 15, y: -2.5 },
                        style: "fieldKeyBold",
                      },

                      {
                        text: Array(defaultLine).fill("_"),
                        style: "fieldKeyBold",
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: 100,
                    text: `วันที่`,
                    alignment: "right",
                    margin: [0, 0, 10, 0],
                  },
                  {
                    stack: [
                      {
                        text: moment(
                          props.reportCovData?.params?.print_date_time || "",
                          "DD/MM/YYYY HH:mm"
                        )
                          .locale("th")
                          .format("DD MMMM YYYY HH:mm"),
                        relativePosition: { x: 15, y: -2.5 },
                        style: "fieldKeyBold",
                      },
                      {
                        text: Array(defaultLine).fill("_"),
                        style: "fieldKeyBold",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            stack: [
              {
                columns: [
                  {
                    width: 100,
                    text: `ผู้รับเอกสาร`,
                    alignment: "right",
                    margin: [0, 0, 10, 0],
                  },
                  {
                    stack: [
                      {
                        text: "",
                        relativePosition: { x: 15, y: -2.5 },
                        style: "fieldKeyBold",
                      },
                      {
                        text: Array(defaultLine).fill("_"),
                        style: "fieldKeyBold",
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: 100,
                    text: `วันที่`,
                    alignment: "right",
                    margin: [0, 0, 10, 0],
                  },
                  {
                    stack: [
                      {
                        text: "",
                        relativePosition: { x: 15, y: -2.5 },
                        style: "fieldKeyBold",
                      },
                      {
                        text: Array(defaultLine).fill("_"),
                        style: "fieldKeyBold",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    pageBreakBefore(
      currentNode: any,
      followingNodesOnPage: any,
      nodesOnNextPage: any,
      previousNodesOnPage: any
    ) {
      const shouldBreakInsideGroup = decidePageBreakInsideGroup(
        {
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
        },
        "signature"
      );

      const shouldBreakInsideColumn = decidePageBreakInsideColumn(
        {
          currentNode,
          followingNodesOnPage,
          previousNodesOnPage,
        },
        { prefix: "column", end: "table-end" }
      );

      return shouldBreakInsideGroup || shouldBreakInsideColumn;
    },
  };
};

export default FormSeparateMedicalExpensesReport;
