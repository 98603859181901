import WasmController from "react-lib/frameworks/WasmController";
import ReceiptList from "issara-sdk/apis/ReceiptList_apps_BIL";
import DepositView from "issara-sdk/apis/DepositView_apps_BIL";
import moment from "moment";
import PrintReceiptByItemView from "issara-sdk/apis/PrintReceiptByItemView_apps_BIL";
import WalletPatientSummaryView from "issara-sdk/apis/WalletPatientSummaryView_apps_BIL";
import WalletTransactionGroupList from "issara-sdk/apis/WalletTransactionGroupList_apps_BIL";
import WalletTypeList from "issara-sdk/apis/WalletTypeList_apps_BIL";
import WalletTransactionGroupCancel from "issara-sdk/apis/WalletTransactionGroupCancel_apps_BILM";
import { createPDFReceipt, HandlePrintReceipt } from "./BillPayment";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormSapiensReceipt from "react-lib/apps/HISV3/BIL/FormSapiensReceipt";
import { bahttext } from "bahttext";
import { formatComma } from "react-lib/utils/tsUtils";
import { priceToTextEN } from "react-lib/apps/HISV3/BIL/sequence/BillPayment";

let toDate = moment();
let year = parseInt(toDate.format("YYYY")) + 543;

export type State = {
  selectedEncounter?: any;
  bilReceiptCodeDetail?: any;
  selectedPatient?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  // Sequence
  BillingDepositSequence?: Partial<{
    sequenceIndex?: string | null;
    billDepositDetail?: {
      walletType?: any;
      selectDeposit?: {
        id?: any;
        code?: any;
        depositHistoryId?: any;
        receipt_no?: any;
        walletValue?: any;
        init_balance?: string;
        patient_paid?: string;
        payment?: string;
        balance?: string;
        mode_code?: string;
        mode_name?: string;
        deposit_receipt?: string;
      };
      sumWallet?: {
        sumTotal?: any;
        sumPayable?: any;
        sumAmount?: any;
        sumRemian?: any;
      };
      editDeposit?: {
        note?: any;
        username?: any;
        password?: any;
      };
    };
    billDepositHistory?: {
      depositCode?: any;
      depositStatus?: any;
      startDate?: any;
      endDate?: any;
      receiptItems?: any;
    };
  }> | null;
};

export const StateInitial: State = {
  BillingDepositSequence: {
    billDepositDetail: {
      selectDeposit: {
        id: "",
        code: null,
        receipt_no: null,
        walletValue: "",
        init_balance: "",
        patient_paid: "",
        payment: "",
        balance: "",
        mode_code: "",
        mode_name: "",
      },
      sumWallet: {
        sumTotal: "0.00",
        sumPayable: "0.00",
        sumAmount: "0.00",
        sumRemian: "0.00",
      },
      walletType: [],
    },
    billDepositHistory: {
      depositCode: "",
      depositStatus: "",
      startDate: `${toDate.format("DD/MM/")}${year}`,
      endDate: `${toDate.format("DD/MM/")}${year}`,
    },
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  divisionDict?: any;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingDepositSequence) return;

  controller.setState({
    BillingDepositSequence: {
      ...StateInitial.BillingDepositSequence,
      ...state.BillingDepositSequence,
    },
  });

  const [walletTypeRes, walletTypeErr, walletTypeNet] = await WalletTypeList.list({
    params: {},
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  const [receiptRes, receiptErr, receiptNet] = await WalletTransactionGroupList.list({
    params: {
      start_date: `${toDate.format("YYYY-MM-DD")}`,
      end_date: `${toDate.format("YYYY-MM-DD")}`,
      type_name: "DEPOSIT",
      patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
    },
    apiToken: controller.apiToken,
  });

  if (walletTypeRes) {
    controller.setState({
      BillingDepositSequence: {
        ...state.BillingDepositSequence,
        sequenceIndex: "Action",
        billDepositDetail: {
          ...StateInitial.BillingDepositSequence?.billDepositDetail,
          walletType: walletTypeRes?.items,
        },
        billDepositHistory: {
          ...StateInitial.BillingDepositSequence?.billDepositHistory,
          receiptItems: receiptRes?.items,
        },
      },
    });
  }
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.BillingDepositSequence) return;

  if (params.action === "selectWallet") {
    const state = controller.getState();

    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });

    const [walletRes, walletErr, walletNet] = await WalletPatientSummaryView.get({
      params: {
        wallet_type: params.id,
        patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    let walletType = state.BillingDepositSequence?.billDepositDetail?.walletType.filter(
      (item: any) => item.id === params.id
    );

    controller.setState({
      BillingDepositSequence: {
        ...state.BillingDepositSequence,
        sequenceIndex: "Action",
        billDepositDetail: {
          ...state.BillingDepositSequence?.billDepositDetail,
          selectDeposit: {
            id: params.id,
            code: walletType?.[0]?.code,
            receipt_no: null,
            walletValue: "",
            init_balance: "0.00",
            patient_paid: "0.00",
            payment: "0.00",
            balance: "0.00",
            mode_code: walletType?.[0]?.id,
            mode_name: walletType?.[0]?.name,
            ...walletRes,
            ...params.data,
          },
          sumWallet: {
            sumTotal: walletRes?.deposit || "0.00",
            sumPayable: params.data ? params.data?.patient_paid : "0.00",
            sumAmount: walletRes?.payment || "0.00",
            sumRemian: walletRes?.balance || "0.00",
          },
        },
      },
    });
  } else if (params.action === "getHistory") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const formatDateYYYYMMDD = (data: any) => {
      let thisDate = moment(data, "DD/MM/YYYY").format("MM-DD");
      let thisYear = Number(moment(data, "DD/MM/YYYY").format("YYYY")) - 543;

      return `${thisYear}-${thisDate}`;
    };

    const [receiptRes, receiptErr, receiptNet] = await WalletTransactionGroupList.list({
      params: {
        status: state.BillingDepositSequence?.billDepositHistory?.depositStatus,
        code: state.BillingDepositSequence?.billDepositHistory?.depositCode,
        type_name: "DEPOSIT",
        type: 3,
        patient: state.selectedEncounter?.patient_id || state.selectedPatient?.id,
        ...(state.BillingDepositSequence?.billDepositHistory?.startDate && {
          start_date: formatDateYYYYMMDD(
            state.BillingDepositSequence?.billDepositHistory?.startDate
          ),
        }),
        ...(state.BillingDepositSequence?.billDepositHistory?.endDate && {
          end_date: formatDateYYYYMMDD(state.BillingDepositSequence?.billDepositHistory?.endDate),
        }),
      },
      apiToken: controller.apiToken,
    });

    if (receiptRes) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        BillingDepositSequence: {
          ...state.BillingDepositSequence,
          billDepositHistory: {
            ...state.BillingDepositSequence?.billDepositHistory,
            receiptItems: receiptRes?.items,
          },
        },
      });

      if (params.data && params.selectDeposit) {
        let docDef: any = { content: [] };
        let getReceipt = receiptRes?.items?.filter(
          (item: any) => item.no === params.selectDeposit?.no
        )?.[0];

        docDef = await FormSapiensReceipt({
          ...params.data,
          receiptType: "refund",
          cancelled_code: getReceipt?.cancelled_code,
        });
        (await getPdfMake(true)).createPdf(docDef).open();
      }
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "onPrintDeposit") {
    const state = controller.getState();

    HandlePrintReceipt(controller as any, {
      id: state.BillingDepositSequence?.billDepositDetail?.selectDeposit?.deposit_receipt,
      isWatermark: true,
      isDeposit: true,
      receiptType: "deposit",
    });

    // const result = await PrintReceiptByItemView.get({
    //   apiToken: controller.apiToken,
    //   pk: state.BillingDepositSequence?.billDepositDetail?.selectDeposit?.id,
    // });

    // const receipt = await createPDFReceipt(result[0]?.receipt);

    // receipt?.open();
  } else if (params.action === "walletCancel") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const [walletRes, walletErr, walletNet] = await WalletTransactionGroupCancel.delete({
      pk: state.BillingDepositSequence?.billDepositDetail?.selectDeposit?.depositHistoryId,
      data: {
        username: state.BillingDepositSequence?.billDepositDetail?.editDeposit?.username,
        password: state.BillingDepositSequence?.billDepositDetail?.editDeposit?.password,
        note: state.BillingDepositSequence?.billDepositDetail?.editDeposit?.note,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division, device: controller.data.device },
    });

    if (walletErr) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
        errorMessage: { ...state.errorMessage, [`${params.card}_walletCancel`]: walletErr },
      });
    } else if (walletNet.status === 204) {
      let selectDeposit = state.BillingDepositSequence?.billDepositDetail?.selectDeposit;
      let balance = formatComma(Number(selectDeposit?.balance));
      let data = {
        created: `${toDate.format("DD/MM/")}${year}`,
        time: toDate.format("HH:mm:ss"),
        hn: state.selectedEncounter?.hn || state.selectedPatient?.hn,
        name: state.selectedEncounter?.patient_name || state.selectedPatient?.full_name_th,
        patientNameEN:
          state.selectedPatient?.full_name_en !== "" && `(${state.selectedPatient?.full_name_en})`,
        fields: [
          {
            bill_mode__name: `คืนเงินมัดจำจากใบเสร็จรับเงินมัดจำเลขที่ ${selectDeposit?.receipt_no} (Deposit refund no.)`,
            claimable__sum: "",
            nonclaimable__sum: balance,
            discount__sum: "",
            net__sum: balance,
          },
        ],
        nonclaimable_sum: balance,
        net_sum: balance,
        cashier: state.django?.user?.full_name,
        price_text: bahttext(selectDeposit?.balance),
        ...(balance && { priceTextEN: priceToTextEN.convert(balance.replaceAll(",", "")) }),
      };

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        BillingDepositSequence: {
          ...state.BillingDepositSequence,
          billDepositDetail: {
            ...state.BillingDepositSequence?.billDepositDetail,
            selectDeposit: {
              id: "",
              code: null,
              receipt_no: null,
              walletValue: "",
              init_balance: "0.00",
              patient_paid: "0.00",
              payment: "0.00",
              balance: "0.00",
            },
            sumWallet: {
              sumTotal: 0.0,
              sumPayable: 0.0,
              sumAmount: 0.0,
              sumRemian: 0.0,
            },
            editDeposit: {
              note: "",
              username: "",
              password: "",
            },
          },
        },
      });

      params.callback();

      Action(controller, { action: "getHistory", selectDeposit: selectDeposit, data: data });
    }
  } else if (params.action === "onAddDeposit") {
    const state = controller.getState();

    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });

    if (Number(params.walletValue) > 0) {
      controller.setState({
        BillingDepositSequence: {
          ...state.BillingDepositSequence,
          billDepositDetail: params.data,
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: {
            error: "กรุณาระบุยอดเงิน",
          },
        },
      });
    }
  } else if (params.action === "onCloseData") {
    const state = controller.getState();

    controller.setState({
      BillingDepositSequence: {
        ...state.BillingDepositSequence,
        billDepositDetail: {
          ...state.BillingDepositSequence?.billDepositDetail,
          selectDeposit: {
            id: "",
            code: null,
            receipt_no: null,
            walletValue: "",
            init_balance: "0.00",
            patient_paid: "0.00",
            payment: "0.00",
            balance: "0.00",
          },
          sumWallet: {
            sumTotal: 0.0,
            sumPayable: 0.0,
            sumAmount: 0.0,
            sumRemian: 0.0,
          },
          editDeposit: {
            note: "",
            username: "",
            password: "",
          },
        },
      },
    });
  }
};
